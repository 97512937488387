import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root'
})
export class HelperService {

	public static sanitizeErrorMessage(message: string): string[] {
		return message.includes('Unexpected token < in JSON')
			? ['The request failed. We have been notified and are working to fix the issue. Please check back again in 30 minutes.']
			: message
				.replace('Unexpected error value: ', '')
				.replace('Network error: Http failure response for (unknown url): 0 Unknown Error', 'Service Timeout')
				.replace(/\"/g, '')
				.replace('{ error: ', '')
				.replace('. }', '.')
				.split('|');
	}

	public static getErrorMessage(res: any): string[] {
		if (res) {
			if (res.graphQLErrors?.length) {
				return this.sanitizeErrorMessage(res.graphQLErrors[0].message);
			} else if (res.networkError?.body?.errors) {
				return this.sanitizeErrorMessage(res.networkError.body.errors.map((e: Error) => e.message).join('|'));
			} else if (res.networkError?.message) {
				return this.sanitizeErrorMessage(res.networkError.message);
			} else if (res.error?.message) {
				if (res.error.message?.response) {
					return this.sanitizeErrorMessage(res.error.message.response);
				} else {
					return this.sanitizeErrorMessage(typeof res.error.message === 'string'
						? res.error.message
						: JSON.stringify(res.error.message));
				}
			} else if (res.message) {
				return this.sanitizeErrorMessage(res.message);
			}
		}

		return ['Unknown Error'];
	}

	public static left(str: string, count: number): string {
		return str.length > count ? str.slice(0, count - str.length) : str;
	}

	public static removeLastCharacter(str: string | null | undefined, char: string): string {
		if (!str) { return ''; }

		return str.endsWith(char) ? this.left(str, str.length - 1) : str;
	}

	public static getPath(path: string, withToken = false): string {
		const token = withToken ? '?access_token=' + environment.apiKey : '';
		return !path.includes('http')
			? environment.baseUrl + path + token
			: path;
	}
}
